import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import bgImage from "assets/images/homepage2.png";
import routes from "routes";
import footerRoutes from "footer.routes";

function GetStarted() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        // action={{
        //   type: "external",
        //   route: "https://www.creative-tim.com/product/material-kit-react",
        //   label: "free download",
        //   color: "default",
        // }}
        // transparent
        // light
        sticky
      />

      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={8} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              textAlign="center"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Getting Started with Your CIEH Certification
            </MKTypography>
            <MKTypography variant="body1" color="white" textAlign="center" mt={2}>
              Learn how to begin your journey and what steps to follow to achieve your Health &
              Safety certification.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 3,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backdropFilter: "saturate(200%) blur(30px)",
        }}
      >
        <Container>
          <Grid container spacing={3} mt={4}>
            <Grid item xs={12}>
              <MKTypography variant="h2" textAlign="center" mb={4}>
                How Do I Start and What Happens Next?
              </MKTypography>
              <MKTypography variant="body1" paragraph>
                <strong>1. Get Enrolled:</strong> Contact Raymond J. Allard Dip SM by email at{" "}
                <a href="mailto:ray.allard@gmail.com">ray.allard@gmail.com</a> to receive further
                information about the programme.
              </MKTypography>
              <MKTypography variant="body1" paragraph>
                <strong>2. Choose Your Course:</strong> Discuss with your tutor which course you
                wish to complete and provide your contact details via our candidate form.
              </MKTypography>
              <MKTypography variant="body1" paragraph>
                <strong>3. Privacy:</strong> Your details will be kept private and only shared with
                CIEH for certification purposes.
              </MKTypography>
              <MKTypography variant="body1" paragraph>
                <strong>4. Payment:</strong> Pay the course fee electronically—a one-off, fully
                inclusive fee.
              </MKTypography>
              <MKTypography variant="body1" paragraph>
                <strong>5. Receive Materials:</strong> You will receive your course book and study
                guidance by post.
              </MKTypography>
              <MKTypography variant="body1" paragraph>
                <strong>6. Start Learning:</strong> Study at your own pace with support from your
                tutor.
              </MKTypography>
              <MKTypography variant="body1" paragraph>
                <strong>7. Ongoing Support:</strong> Receive continuous support as you progress
                towards your nationally recognised CIEH certificate.
              </MKTypography>
            </Grid>
          </Grid>
        </Container>

        <MKBox pt={4} pb={8}>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  You are now on your journey to achieve a CIEH nationally recognised certificate in
                  Health and Safety
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Join today and gain a nationally recognised Health & Safety qualification.
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default GetStarted;

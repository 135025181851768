import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import bgImage from "assets/images/homepage2.png";
import routes from "routes";
import footerRoutes from "footer.routes";

function Achievement() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        // action={{
        //   type: "external",
        //   route: "https://www.creative-tim.com/product/material-kit-react",
        //   label: "free download",
        //   color: "default",
        // }}
        // transparent
        // light
        sticky
      />

      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              textAlign="center"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              CIEH Occupational Health & Safety Certification
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Earn a qualification that enhances safety knowledge and career prospects in various
              industries.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2" mt={9} mb={2}>
            Why Pursue This Qualification?
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Enhance your job applications, career advancement, and safety knowledge to protect
            yourself and others.
          </MKTypography>
        </Grid>

        <MKBox component="section" py={3}>
          <Container>
            <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
              <Grid item xs={12} md={4}>
                <DefaultCounterCard
                  title="Career Advancement"
                  description="Helps with job promotions, from frontline worker to team leader."
                />
              </Grid>
              <Grid item xs={12} md={4} display="flex">
                <Divider
                  orientation="vertical"
                  sx={{ display: { xs: "none", md: "block" }, mx: 0 }}
                />
                <DefaultCounterCard
                  title="Recognised Certification"
                  description="Trusted by employers like John Lewis, Disney, and Walmart."
                />
                <Divider
                  orientation="vertical"
                  sx={{ display: { xs: "none", md: "block" }, ml: 0 }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DefaultCounterCard
                  title="Flexible Learning"
                  description="Face-to-face, blended, or distance learning options available."
                />
              </Grid>
            </Grid>
          </Container>
        </MKBox>

        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
          mt={6}
        >
          <MKTypography variant="h2" mb={2}>
            Who Are the CIEH and What is a Training Centre?
          </MKTypography>
        </Grid>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="school"
                title="Awarding Organisation"
                description="The Chartered Institute of Environmental Health (CIEH) is a leading professional body and awarding organisation, providing qualifications in Health & Safety, Food Safety, and Environmental subjects. They issue around 500,000 certificates annually, trusted by clients like John Lewis, Disney, and Walmart."
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="public"
                title="Global Recognition"
                description="CIEH qualifications are recognised in over 50 countries worldwide. Their vocational certifications are considered highly reliable by employers across the UK and beyond, making them a trusted standard in numerous industries."
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="how_to_reg"
                title="Trusted Training Centres"
                description="CIEH-approved training centres and registered trainers deliver courses nationwide. These centres, like Raymond J. Allard Dip SM, offer flexible learning options, including face-to-face, blended, and distance learning, to suit different learning needs."
              />
            </Grid>
          </Grid>
        </Container>

        <MKBox pt={4} pb={8}>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Ready to Take Your Safety Knowledge to the Next Level?
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Join today and gain a nationally recognised Health & Safety qualification.
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Achievement;

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import bgImage from "assets/images/homepage2.png";
import routes from "routes";
import footerRoutes from "footer.routes";

function Achievement() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        // action={{
        //   type: "external",
        //   route: "https://www.creative-tim.com/product/material-kit-react",
        //   label: "free download",
        //   color: "default",
        // }}
        // transparent
        // light
        sticky
      />

      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              textAlign="center"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Certification Process
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Achieve your Occupational Health and Safety certificate with a flexible study plan
              designed around your schedule. Complete your studies in 2 to 8 weeks, culminating in a
              multiple-choice assessment.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={3}>
          <Container>
            <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
              <Grid item xs={12} md={4}>
                <DefaultCounterCard
                  title="Flexible Study Options"
                  description="Choose between 2-8 weeks with blended learning options, including virtual and face-to-face support."
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DefaultCounterCard
                  title="All-Inclusive Fee"
                  description="Your fee covers registration, course materials, tutor support, assessment, and your official CIEH certificate."
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DefaultCounterCard
                  title="Career Advancement"
                  description="Enhance your resume with a CIEH-accredited certification and improve workplace safety practices."
                />
              </Grid>
            </Grid>
          </Container>
        </MKBox>

        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
          mt={6}
        >
          <MKTypography variant="h4" fontWeight="bold" mb={2} mt={6}>
            Interested in Enrolling?
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Contact Raymond J. Allard Dip.SM for more details at{" "}
            <strong>Ray.Allard@gmail.com</strong> or visit{" "}
            <a href="http://www.rayallardsafety.com">www.rayallardsafety.com</a>.
          </MKTypography>
        </Grid>

        <MKBox pt={4} pb={8}>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Ready to Take Your Safety Knowledge to the Next Level?
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Join today and gain a nationally recognised Health & Safety qualification.
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Achievement;

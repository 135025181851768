/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import GetStarted from "layouts/get-started";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";
import Divider from "@mui/material/Divider";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/homepage2.png";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        // action={{
        //   type: "internal",
        //   route: "/get-started",
        //   label: "Get Started",
        //   color: "info",
        //   // component: GetStarted,
        // }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              textAlign="center"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Essential Health & Safety Training for Every Workplace
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              CIEH Foundation Certificate in Occupational Health & Safety
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
          mb={0}
        >
          <MKTypography variant="h2" mt={9} mb={2}>
            Certification Process
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            This nationally recognised certificate is accredited by the Chartered Institute of
            Environmental Health and awarded upon successful completion of a multiple-choice
            question paper. The exam is taken at the end of your learning and study arrangements,
            ensuring a comprehensive understanding of health and safety principles.
          </MKTypography>
        </Grid>

        <Counters />

        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
          mt={6}
        >
          <MKTypography variant="h2" mb={2}>
            Who Should Attend?
          </MKTypography>
        </Grid>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="person"
                title="Frontline Staff"
                description="Gain crucial health and safety knowledge for day-to-day operations."
                // action={{
                //   type: "external",
                //   route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                //   label: "Read more",
                // }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="person"
                title="Team Leaders & Supervisors"
                description="Perfect for those stepping into new responsibilities."
                // action={{
                //   type: "external",
                //   route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                //   label: "Read more",
                // }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="person"
                title="Apprentices"
                description="Strengthen foundational skills to support future career growth."
                // action={{
                //   type: "external",
                //   route: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
                //   label: "Read more",
                // }}
              />
            </Grid>
          </Grid>
        </Container>
        <Information />
        {/* <DesignBlocks /> */}
        {/* <Pages /> */}
        {/* <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container> */}

        {/* <Testimonials /> */}
        {/* <Download /> */}
        <MKBox pt={4} pb={8}>
          <Container>
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                lg={12}
                ml="auto"
                sx={{ textAlign: { xs: "center", lg: "center" } }}
              >
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Empower Your Team. Protect Your Workplace.
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Join today and make health and safety second nature.
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
